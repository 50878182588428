<template>
  <div class="common_sub_container">
    <h2>OUTBOUND MARKUP REPORTS</h2>
    <section>
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Date Range
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Courier Company
                  <span></span>
                </label>
                <select v-model="post_data.delivery_provider">
                  <option value="">All</option>
                  <option
                    v-for="(carrier,i) in carriers"
                    :key="'carriers'+i"
                    :value="carrier.id"
                  >{{carrier.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Client
                  <span></span>
                </label>
                <select v-model="post_data.client">
                  <option value="all">All</option>
                  <option value="kwm">KWM</option>
                  <option value="personal">Personal</option>
                  <option value="base client">Base</option>
                </select>
              </div>
            </td>
            <td style="width: 8%;padding-top: 38px;">
              <a href="javascript:void(0)" :class="['link_bt', 'bt_save', 'float-right' ,{ 'disabled': search_btn.disabled }]"
                :aria-disabled="search_btn.disabled" @click="fetchReport" 
                :disabled="search_btn.disabled">{{ search_btn.value }}</a>
            </td>
            <!-- <td>
              <div class="label_and_element_wrapper">
                <label>
                  Status
                  <span></span>
                </label>
                <select   v-model="post_data.status">
                  <option value="all">All</option>
                  <option value="Received">Received</option>
                  <option value="Dispatched">Dispatched</option>
                  <option value="Scan Run">Scan Run</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Duplicate">Duplicate</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Refused Delivery">Refused Delivery</option>
                  <option value="Not Available">Not Available</option>
                </select>
              </div>
            </td> -->
          </tr>
          <!-- <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Client
                  <span></span>
                </label>
                <select v-model="post_data.client">
                  <option value="all">All</option>
                  <option value="personal">Personal</option>
                  <option value="base client">Base</option>
                </select>
              </div>
            </td>
            <td></td>
            <td>
              <br>
              <br>
              <a href="javascript:void(0)" class="link_bt bt_save float-right" @click="fetchReport">Search</a>
            </td>
          </tr> -->
        </table>
      </div>
    </section>
    <section  v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper"  v-if="Object.keys(reports).length > 0">
        <header>
          <h3>Outbound Markup Report {{ auth().user_type != 'super_admin' ? ` - ${auth().branch}` : '' }}</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="summary_table_container">
          <!-- <h2>{{key}}</h2> -->
          <table id="inbound_delivery_report_table">
            <tr>

              <td><b>Reference Number</b></td>
              <td><b>Date Booked</b></td>
              <td><b>Client Personal/Base</b></td>
              <td><b>Requester</b></td>
              <td><b>Courier Company</b></td>
              <td><b>Pickup Location</b></td>
              <td><b>Delivery Location</b></td>
              <td><b>Charged Cost</b></td>
              <td><b>Courier Cost</b></td>
              <td><b>Markup Value</b></td>
            </tr>
            <tr v-for="(courier,i) in reports" :key="i">
                <td>{{courier.airway_bill}}</td>
                <td>{{courier.created_at | formatDate}}</td>
                <td>{{ serviceType(courier)}}</td>
                <td>{{ courier.requester_name}}</td>
                <td>{{ courier.provider_name.name}}</td>
                <td>{{ courier.pickup_city }}</td>
                <td>{{ courier.delivery_city }}</td>
                <td>$ {{ courier.cost || 0}}</td>
                <td>$ {{ courier.courier_rate}}</td>
                <td>$ {{ (courier.cost - courier.courier_rate).toFixed(2) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <h4 v-if="reports.length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "Reports",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      costcenters: [],
      goods: [],
      services: [],
      divisions: [],
      units: [],
      carriers:[],
      reports: {},
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        delivery_provider:'',
        status: "all",
        client: "all",
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
      show_data: false,
      messengers:[],
      search_btn: {
        value:'Search',
        disabled:false
      }
    }
  },
	filters: {
		formatDate(value) {
			if (!value) {
				return;
			}
			return moment(String(value)).format('DD/MM/YYYY')
		},
	},
  async created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", "Outbound Postal Report"]);
    await this.fetchCarrier()
  },
  methods: {
    serviceType(courier) {
      if (!courier.client || 'kwm' == courier.client) {
        return "King & Wood"
      } else if (!courier.client || 'base client' == courier.client) {
        return "Base Client"
      } else if (!courier.client || 'personal' == courier.client) {
        return "Personal"
      }
    },
    dateChanged(daterange) {
      this.resetData();
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    resetData() {
      this.show_data = false;
      this.reports = {};
    },
    fetchCarrier() {
      this.axios
        .get("/api/provider/get-providers-by-category/outbound")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    async fetchReport() {
      this.resetData();

      this.search_btn.disabled = true;
      this.search_btn.value = 'Searching...';

      await this.axios
        .post("/api/reports/get-outbound-markup-report", this.post_data)
        .then(response => {
          this.show_data = true;
          // console.log(response.data.data);
          this.reports = response.data.data;
          // console.log(this.reports);
        })
        .catch(error => {
          console.log(error);
        });

      this.search_btn.disabled = false;
      this.search_btn.value = 'Search';

    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/get-outbound-markup-report/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      var elementToPrint = document.getElementById('inbound_delivery_report_table');
      if (elementToPrint) {
        var printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write('<style>table {width: 100%; border-collapse: collapse;} td {border: 1px solid black; padding: 5px;}</style>');
        printWindow.document.write(elementToPrint.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
      }
    }
  }
};
</script>
<style  lang="scss" scoped>
.disabled {
  // pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
#inbound_delivery_report_table {
    // display: block;
    overflow-x: auto;
    white-space: nowrap;
    // height: 500px;
    overflow-y: auto;
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
}
.flatpickr-calendar.lol {
  border: 5px solid red;
}
.dashboard_row {
  width: 100%;
  display: flex;
  cursor:default;
  .dashboard_block {
    background: #fff;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid #efefef;
  }
  .second_row_cover1 {
    width:100%;
  }
  .dashboard-row-divider{
    display: flex;
    .dashboard_block {
      border-top: 5px solid #06A5ED;
      width: 50%;
      height: 250px;
      margin-right: 20px;
      &.dashboard_block3 {
        border-top: 5px solid #06A5ED;
        width: 40%;
        height: 200px;
        margin-bottom: 40px;
      }
    }
  }
}

.pending_blocks_row {
  display: flex;
  margin: auto;
  justify-content: space-around;
  .pending_block {
    width: 30%;
    height: auto;
    text-align: center;
    position: relative;
    padding: 10px;
    i.material-icons {
      font-size: 50px;
      border: 2.75px solid ;
      border-radius: 50%;
      padding: 10px;
      margin-bottom: 3px;
    }
    .red {
      color:red;
      border-color:red;
    }
    cursor: pointer;
    .overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      font-size: 16px;
      text-align: center;
      color: #fff;
      display: none;
      justify-content: center;
      flex-direction: column;
    }
    &:hover .overlay {
      display: flex;
    }
    img {
      display: block;
      width: 75px;
      height: 75px;
      margin: auto;
    }
    p.total {
      padding: 5px 0;
      text-align: center;
      font-size: 18px;
      &.numbers {
        font-size: 25px;
        font-weight: 600;
        &.clr1 {
          color:#ffcf01;
        }
        &.clr2 {
          color: #06A5ED;
        }
        &.clr3 {
          color: #49a942;
        }
        &.clr4 {
          color: #004a7c;
        }
      }
      &.category {
        font-size: 19px;
        color: #333;
      }
    }
  }
}
</style>
<style  scoped>
.link_bt {
  margin-left: 10px;
}

.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}
.summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>